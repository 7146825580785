import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../services/AuthService";

export const registration = createAsyncThunk(
  "auth/registration",
  async ({ email, password, passwordConfirm }, { rejectWithValue }) => {
    try {
      const response = await AuthService.registration(
        email,
        password,
        passwordConfirm
      );

      return response.data;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await AuthService.login(email, password);

      localStorage.setItem("token", response.data.access);
      localStorage.setItem("refreshToken", response.data.refresh);

      return response.data;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.logout();
      localStorage.clear();
      //   return response.data;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const checkAuth = createAsyncThunk(
  "auth/checkAuth",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.checkAuth();
      localStorage.setItem("token", response.data.access);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const forgotPassTh = createAsyncThunk(
  "auth/forgotPass",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await AuthService.forgotPass(email);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const newPassTh = createAsyncThunk(
  "auth/newPass",
  async (
    { code_confirm, new_password, password_confirm },
    { rejectWithValue }
  ) => {
    try {
      const response = await AuthService.newPass(
        code_confirm,
        new_password,
        password_confirm
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

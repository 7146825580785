import { createSlice } from "@reduxjs/toolkit";
import {
  createQuestTh,
  deleteMyQuestTh,
  getAllQuestTh,
  getAreaTh,
  getServicesTh,
  getTypeOfGirlTh,
  myQuestTh,
  sendCommentTh,
  starsVoteTh,
} from "../middleware/QuestThunk";

const initialState = {
  data: null,
  error: false,
  isLoading: false,
  isLoadingQuest: false,
  quests: [],
  myQuest: "",
  myQuestState: false,
  myQuestError: false,
  created: false,
  services: [],
  areas: [],
  typeOfGirl: [],
  filtered: [],
};

const QuestSlice = createSlice({
  name: "quest",
  initialState,
  reducers: {
    setFiltered: (state, action) => {
      state.filtered = action.payload;
    },
    setCreated: (state) => {
      state.created = false;
    },
    setMyQuestError: (state) => {
      state.myQuestError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //createQuestTh
      .addCase(createQuestTh.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createQuestTh.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.created = true;
      })
      .addCase(createQuestTh.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.created = false;
      })
      //myQuest
      .addCase(myQuestTh.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(myQuestTh.fulfilled, (state, action) => {
        state.isLoading = false;
        state.myQuestError = false;
        state.myQuestState = true;
        state.myQuest = action.payload;
      })
      .addCase(myQuestTh.rejected, (state, action) => {
        state.isLoading = false;
        state.myQuestError = true;
      })
      //deleteMyQyest
      .addCase(deleteMyQuestTh.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteMyQuestTh.fulfilled, (state, action) => {
        state.isLoading = false;
        state.myQuestState = false;
      })
      .addCase(deleteMyQuestTh.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      //getAllQuest
      .addCase(getAllQuestTh.pending, (state) => {
        state.isLoadingQuest = true;
      })
      .addCase(getAllQuestTh.fulfilled, (state, action) => {
        state.isLoadingQuest = false;
        state.quests = action.payload;
      })
      .addCase(getAllQuestTh.rejected, (state, action) => {
        state.isLoadingQuest = false;
        state.error = action.payload;
      })
      //deleteMyQyest
      .addCase(getServicesTh.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServicesTh.fulfilled, (state, action) => {
        state.isLoading = false;
        state.services = action.payload;
      })
      .addCase(getServicesTh.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      //getArea
      .addCase(getAreaTh.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAreaTh.fulfilled, (state, action) => {
        state.isLoading = false;
        state.areas = action.payload;
      })
      .addCase(getAreaTh.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      //getTypeOfGirl
      .addCase(getTypeOfGirlTh.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTypeOfGirlTh.fulfilled, (state, action) => {
        state.isLoading = false;
        state.typeOfGirl = action.payload;
      })
      .addCase(getTypeOfGirlTh.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      //sendComment
      .addCase(sendCommentTh.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendCommentTh.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(sendCommentTh.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      //starsVote
      .addCase(starsVoteTh.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(starsVoteTh.fulfilled, (state, action) => {
        // state.isLoading = false;
      })
      .addCase(starsVoteTh.rejected, (state, action) => {
        // state.isLoading = false;
        // state.error = action.payload;
      });
  },
});

export const { setFiltered, setCreated, setMyQuestError } = QuestSlice.actions;
export default QuestSlice.reducer;

const Rules = () => {
  return (
    <div className="rules">
      <div className="container">
        <div className="rules__box">
          <h1 className="rules__title">Правила размещения анкет</h1>
          <div className="rules__block">
            <ul className="rules__list">
              <li>
                Все анкеты размещаются непосредственно пользователями сайта,
                достигшими совершеннолетия.
              </li>
              <li>
                Размещение платное, но можно получить скидку при оптовой покупке
                мест.
              </li>
              <li>
                В случае блокировки сайта Роскомнадзором, сайт переезжает на
                новое имя, а денежные средства замораживаются (но не
                возвращаются). То есть деньги с вас не списываются до тех пор,
                пока новый сайт не поднимется в поисковиках до прежних значений.
                Как только сайт поднимается опять, отчет начинается заново.
              </li>
              <li>
                Администрация сайта не имеет отношения к содержимому анкет и,
                предоставляемыми индивидуалками, услугам.
              </li>
              <li>
                Отметка "Фото проверено" ставиться на анкеты, чьи владельцы
                прислали фото подтверждения на нашу почту или ватсапп. Смотри
                раздел контакты
              </li>
            </ul>
          </div>

          <div className="rules__forbidden-block">
            <h2 className="rules__forbidden">ЗАПРЕЩЕНО:</h2>
            <ul className="rules__list rules__list_forbidden">
              <li>
                Просить предоплату с клиентов в любой форме. До момента личной
                встречи никаких просьб на такси, купить видео, и так далее.
              </li>
              <li>Размещать фото половых органов.</li>
              <li>Фотографии с логотипами других сайтов.</li>
            </ul>
          </div>

          <div className="rules__prices-block">
            <h2 className="rules__prices">ЦЕНЫ:</h2>
            <ul className="rules__list reules__list_prices">
              <li>
                - Стоимость размещения 1 анкеты на 1 месяц - 1000 рублей. Подъем
                наверх опустившейся анкеты - тоже 1000 рублей. Способы оплаты вы
                можете узнать в личном кабинете, после регистрации.
              </li>
              <li>
                - После размещения анкета появляется наверху. После каждого
                продления анкета также поднимается наверх. Например у вас
                кончился месяц, вы продлили, и снова первые, до тех пор пока
                кто-то не подаст новую анкету или не продлит старую. Но вы
                можете продлить старую раньше и подниметесь вверх снова.
              </li>
              <li>- Спецразмещения, ТОПы, закрепления анкет вверху - нет.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;

import { configureStore } from "@reduxjs/toolkit";
import burgerReducer from "./reducers/BurgerSlice";
import authReducer from "./reducers/AuthSlice";
import questReducer from "./reducers/QuestSlice";
import chatReducer from "./reducers/ChatSlice";
import interestsReducer from "./reducers/InterestsSlice";
import webSocketReducer from "./reducers/WebSocketSlice";

export const store = configureStore({
  reducer: {
    burger: burgerReducer,
    auth: authReducer,
    quest: questReducer,
    chat: chatReducer,
    interests: interestsReducer,
    websocket: webSocketReducer,
  },
});

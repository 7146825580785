import { createAsyncThunk } from "@reduxjs/toolkit";
import QuestService from "../../services/QuestService";

// FileList это не массив, надо конвертировать
export const createQuestTh = createAsyncThunk(
  "quest/create",
  async ({ ...data }, { rejectWithValue }) => {
    try {
      const formData = new FormData();

      for (const [key, value] of Object.entries(data)) {
        if (key === "photo") {
          Array.from(value).forEach((file) => {
            formData.append("photo", file);
          });
        } else if (key === "services") {
          // formData.append("services", value);
          Array.from(value).forEach((service) => {
            formData.append("services", service);
          });
        } else if (key === "type_of_meet") {
          Array.from(value).forEach((meet) => {
            formData.append("type_of_meet", meet);
          });
        } else if (key === "type_of_girl" && value === "") {
        } else {
          formData.append(key, String(value));
        }
      }

      const response = await QuestService.createQuest(formData);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const myQuestTh = createAsyncThunk(
  "quest/myQuest",
  async (_, { rejectWithValue }) => {
    try {
      const response = await QuestService.myQuest();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const getAllQuestTh = createAsyncThunk(
  "quest/getAllQuest",
  async (_, { rejectWithValue }) => {
    try {
      const response = await QuestService.getAllQuest();
      const result = response.data;

      const sort = result.sort(
        (a, b) => (b.is_vip ? 1 : 0) - (a.is_vip ? 1 : 0)
      );

      return sort;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const deleteMyQuestTh = createAsyncThunk(
  "quest/deleteMyQuest",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await QuestService.deleteMyQuest(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const getServicesTh = createAsyncThunk(
  "quest/getServices",
  async (_, { rejectWithValue }) => {
    try {
      const response = await QuestService.getServices();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const getAreaTh = createAsyncThunk(
  "quest/getArea",
  async (_, { rejectWithValue }) => {
    try {
      const response = await QuestService.getArea();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const getTypeOfGirlTh = createAsyncThunk(
  "quest/getTypeOfGirl",
  async (_, { rejectWithValue }) => {
    try {
      const response = await QuestService.getTypeOfGirl();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const sendCommentTh = createAsyncThunk(
  "quest/sendComment",
  async ({ id, owner, content }, { rejectWithValue }) => {
    try {
      const response = await QuestService.sendComment(id, owner, content);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const starsVoteTh = createAsyncThunk(
  "quest/starsVote",
  async ({ id, stars }, { rejectWithValue }) => {
    try {
      const response = await QuestService.starsVote(id, stars);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setConnected, setMessages } from "../store/reducers/WebSocketSlice";
import { getConnectedTh } from "../store/reducers/ChatSlice";

const useWebSocket = (url) => {
  const socketRef = useRef(null);
  const dispatch = useDispatch();
  // const [connected, setConnected] = useState(false);
  // const [messages, setMessages] = useState([]);

  useEffect(() => {
    socketRef.current = new WebSocket(url);

    socketRef.current.onopen = () => {
      dispatch(setConnected(true));
      dispatch(getConnectedTh());
      console.log("Connection established");
    };

    socketRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      dispatch(setMessages(message));
    };

    socketRef.current.onclose = () => {
      dispatch(setConnected(false));
      console.log("Socket closed");
    };

    socketRef.current.onerror = () => {
      dispatch(setConnected(false));
      console.log("Socket error");
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [url]);

  const sendMessage = (message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
    }
  };

  return { sendMessage };
};
// connected, messages,
export default useWebSocket;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getAreaTh,
  getServicesTh,
  getTypeOfGirlTh,
} from "../../store/middleware/QuestThunk";
import { setFiltered } from "../../store/reducers/QuestSlice";

const Filter = () => {
  const { services, areas, quests, typeOfGirl, filtered } = useSelector(
    (state) => state.quest
  );
  const dispatch = useDispatch();
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [FData, setFData] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    dispatch(getServicesTh());
    dispatch(getAreaTh());
    dispatch(getTypeOfGirlTh());
  }, []);

  const toggleAdvanced = () => {
    setAdvancedSearch((prev) => !prev);
  };

  const onSubmit = (data) => {
    const filteredEntries = Object.entries(data).filter(
      ([key, value]) => value !== ""
    );

    const filteredData = Object.fromEntries(filteredEntries);

    let results = quests;

    if (filteredData.price) {
      const [first, second] = filteredData.price.split("-");
      results = results.filter((item) => {
        return item.price >= +first && item.price <= +second;
      });
    }

    if (filteredData.area) {
      results = results.filter((item) => item.area.includes(filteredData.area));
    }

    if (filteredData.service) {
      results = results.filter((item) =>
        item.services.includes(filteredData.service)
      );
    }

    // if (filteredData.type_of_girl) {
    //   results = results.filter((item) =>
    //     item.type_of_girl.includes(filteredData.type_of_girl)
    //   );
    // }

    if (filteredData.age) {
      const [first, second] = filteredData.age.split("-");
      results = results.filter(
        (item) => +item.age > +first && +item.age < +second
      );
    }

    if (filteredData.height) {
      switch (filteredData.height) {
        case "1":
          results = results.filter((item) => item.height <= 160);
          break;
        case "2":
          results = results.filter((item) => item.height <= 170);
          break;
        case "3":
          results = results.filter((item) => item.height <= 170);
          break;
      }
    }

    if (filteredData.weight) {
      switch (filteredData.weight) {
        case "1":
          results = results.filter((item) => item.weight < 40);
          break;
        case "2":
          results = results.filter(
            (item) => item.weight < 50 && item.weight > 40
          );
          break;
        case "3":
          results = results.filter(
            (item) => item.weight < 60 && item.weight > 50
          );
          break;
        case "4":
          results = results.filter(
            (item) => item.weight < 80 && item.weight > 60
          );
          break;
        case "5":
          results = results.filter((item) => item.weight > 100);
          break;
      }
    }

    if (filteredData.boobs) {
      results = results.filter(
        (item) => +item.bust_size === +filteredData.boobs
      );
    }

    if (filteredData.type_of_meet) {
      results = results.filter((item) =>
        item.type_of_meet.includes(filteredData.type_of_meet)
      );
    }

    setFData(results);
    dispatch(setFiltered(results));
  };

  return (
    <div className="filter">
      <div className="container">
        <div className="filter__row">
          <form className="filter__form" onSubmit={handleSubmit(onSubmit)}>
            <p className="filter__text">Быстрый поиск:</p>
            <div className="filter__base">
              <select {...register("area")} className="filter__neighborhood">
                <option value="">Выберите район</option>
                {areas.map((item, index) => (
                  <option key={index} value={item.area}>
                    {item.area}
                  </option>
                ))}
              </select>
              {errors.area && <p>{errors.area.message}</p>}

              <select {...register("price")} className="filter__price">
                <option value="">Выберите цену</option>
                <option value="500-1000">500-1000</option>
                <option value="1000-1500">1000-1500</option>
                <option value="1500-2000">1500-2000</option>
                <option value="2000-2500">2000-2500</option>
                <option value="2500-3000">2500-3000</option>
                <option value="3000-5000">3000-5000</option>
                <option value="5000-7000">5000-7000</option>
                <option value="7000-10000">7000-10000</option>
                <option value="10000-15000">10000-15000</option>
              </select>
              {errors.price && <p>{errors?.price?.message}</p>}

              <select {...register("service")} className="filter__service">
                <option value="">Выберите услугу</option>
                {services.map((item, index) => (
                  <option key={index} value={item.service}>
                    {item.service}
                  </option>
                ))}
              </select>
              {errors.service && <p>{errors.service.message}</p>}

              {/* <select
                {...register("type_of_girl")}
                className="filter__type_of_girl"
              >
                <option value="">Выберите уникальность</option>
                {typeOfGirl.map((item, ind) => (
                  <option key={ind} value={item.type}>
                    {item.type}
                  </option>
                ))}
              </select>
              {errors.service && <p>{errors.service.message}</p>} */}

              <button className="filter__search-btn" type="submit">
                Поиск
              </button>
            </div>
            <div
              onClick={toggleAdvanced}
              className="filter__advanced-search-btn"
            >
              <button type="button">Расширенный поиск</button>
            </div>
          </form>

          {advancedSearch && (
            <form className="filter__form_2">
              <select {...register("age")} className="filter__age">
                <option value="">Возраст</option>
                <option value="18-20">18-20</option>
                <option value="20-25">20-25</option>
                <option value="25-30">25-30</option>
                <option value="30-40">30-40</option>
                <option value="40-50">40-50</option>
                <option value=">60">{">"}60</option>
              </select>
              {errors.age && <p>{errors.age.message}</p>}

              <select {...register("height")} className="filter__tall">
                <option value="">Рост</option>
                <option value="1">Миниатюрные</option>
                <option value="2">Среднего роста</option>
                <option value="3">Высокие</option>
              </select>
              {errors.height && <p>{errors.height.message}</p>}

              <select {...register("weight")} className="filter__weigth">
                <option value="">Вес</option>
                <option value="1">Невесомые</option>
                <option value="2">Легкие</option>
                <option value="3">Средние</option>
                <option value="4">Пышки</option>
                <option value="5">Толстушки</option>
              </select>
              {errors.weight && <p>{errors.weight.message}</p>}

              <select {...register("boobs")} className="filter__boobs">
                <option value="">Грудь</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
              {errors.boobs && <p>{errors.boobs.message}</p>}

              <select {...register("type_of_meet")} className="filter__place">
                <option value="">Место встречи</option>
                <option value="Апартаменты">Апартаменты</option>
                <option value="Выезд">Выезд</option>
                <option value="В авто">В авто</option>
              </select>
              {errors.place && <p>{errors.place.message}</p>}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filter;

import { useEffect, useState } from "react";

const Comment = ({ owner, content }) => {
  // const [time, setTime] = useState(null);

  // useEffect(() => {
  //   const fullDate = new Date();
  //   const year = fullDate.getFullYear();
  //   const month = String(fullDate.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
  //   const day = String(fullDate.getDate()).padStart(2, "0");
  //   const hours = String(fullDate.getHours()).padStart(2, "0");
  //   const minutes = String(fullDate.getMinutes()).padStart(2, "0");
  //   // const seconds = String(fullDate.getSeconds()).padStart(2, "0");

  //   const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
  //   setTime(formattedDate);
  //   // console.log(formattedDate); // Пример: "2024-08-06 14:56:23"
  // }, []);

  return (
    <div className="comment">
      <div className="comment__row">
        <div className="comment__logo"></div>

        <div className="comment__content">
          <h6 className="comment__name">
            {owner}
            {/* <span className="comment__date">{time}</span> */}
          </h6>
          <p className="comment__message">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default Comment;

import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CreateQuest from "../pages/CreateQuest/CreateQuest";
import MyQuest from "../pages/MyQuest/MyQuest";

const PrivateRoutes = () => {
  const { isAuth, isAuthProcessing } = useSelector((state) => state.auth);

  if (!isAuth && !isAuthProcessing) {
    return <Navigate to="/login" />;
  }

  return (
    <Routes>
      <Route path="create-quest" element={<CreateQuest />} />
      <Route path="my-quest" element={<MyQuest />} />
    </Routes>
  );
};

export default PrivateRoutes;

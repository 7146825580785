import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registration } from "../../store/middleware/AuthThunk";
import { setError, setIsRegistrated } from "../../store/reducers/AuthSlice";
import Errors from "../../components/Errors/Errors";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

const Register = () => {
  const dispatch = useDispatch();
  const { error, isLoading, isRegistrated } = useSelector(
    (state) => state.auth
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = (data) => {
    dispatch(
      registration({
        email: data.email,
        password: data.password,
        passwordConfirm: data.passwordConfirm,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(setIsRegistrated());
      dispatch(setError(false));
    };
  }, []);

  if (isLoading) {
    return (
      <div className="container">
        <h1 className="loading">Загрузка</h1>
      </div>
    );
  }

  if (isRegistrated) {
    return (
      <div className="container">
        <div className="success">
          Вы успешно зарегистрировались,{" "}
          <Link style={{ color: "red" }} to="/login">
            войти
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="register auth">
      <div className="container">
        <h2 className="register__title">Регистрация пользователя</h2>
        <div className="register__required-fields">
          <p>
            <span>*</span> Обязательное поле
          </p>
        </div>

        {/* errors block */}
        {error && <Errors>Пользватель с такими данными уже есть</Errors>}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="register__form"
          action=""
        >
          {errors.password && (
            <p className="register__errorM">{errors.password.message}</p>
          )}
          <div className="register__password">
            <label htmlFor="">Пароль *</label>
            <input
              {...register("password", {
                required: "Пароль обязателен",
                minLength: {
                  value: 8,
                  message: "Введите не менее 8 символов",
                },
                pattern: {
                  value: /^(?=.*[A-Z]).{8,}$/,
                  message: "Пароль должен начинаться с заглавной буквы",
                },
              })}
              type="password"
            />
          </div>

          {errors.passwordConfirm && (
            <p className="register__errorM">{errors.passwordConfirm.message}</p>
          )}
          <div className="register__re-password">
            <label htmlFor="">Повтор пароля *</label>
            <input
              {...register("passwordConfirm", {
                required: "Обязательное поле",
                validate: (value) =>
                  value === watch("password") || "Пароли не совпадают",
              })}
              type="password"
            />
          </div>

          {errors.email && (
            <p className="register__errorM">{errors.email.message}</p>
          )}
          <div className="register__email">
            <label htmlFor="">Адрес электронной почты *</label>
            <input
              {...register("email", {
                required: "Обязательное поле",
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: "Введите валидный email",
                },
              })}
              type="email"
            />
          </div>
          <div className="register__btns">
            <button className="register__reg-btn">Регистрация</button>
            <button className="register__cancel">Отмена</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;

import "./App.css";
import RootLayout from "./components/layouts/RootLayout";
import HomePage from "./pages/HomePage/HomePage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Men from "./pages/Men/Men";
import Contacts from "./pages/Contacts/Contacts";
import Rules from "./pages/Rules/Rules";
import Map from "./pages/Map/Map";
import Interesting from "./pages/Interesting/Interesting";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import CardProfile from "./components/CardProfile/CardProfile";
import PrivateRoutes from "./routes/PrivateRoutes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { checkAuth } from "./store/middleware/AuthThunk";
import ChangeQuest from "./pages/ChangeQuest/ChangeQuest";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import InterestingPage from "./pages/Interesting/InterestingPage";
import { myQuestTh, getAllQuestTh } from "./store/middleware/QuestThunk";
import useWebSocket from "./hooks/useWebSocket";
import NotFound from "./components/NotFound/NotFound";
import { getChatTh } from "./store/reducers/ChatSlice";

function App() {
  const { sendMessage } = useWebSocket(
    "wss://sex-prostitutki-21.ru/ws/chat/room/"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!localStorage.getItem("token")) {
      dispatch(myQuestTh());
    }
    dispatch(getChatTh());
    dispatch(checkAuth());
  }, [dispatch]);

  const handleSendMessage = (message) => {
    sendMessage(message);
  };

  return (
    <BrowserRouter>
      <div className="app">
        <Routes>
          <Route path="/" element={<RootLayout />}>
            <Route
              path="/"
              element={<HomePage sendMessageFun={handleSendMessage} />}
            />
            <Route path="/men" element={<Men />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/map" element={<Map />} />
            <Route path="/interesting" element={<Interesting />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-pass" element={<ForgotPassword />} />
            <Route path="card/card-profile/:id" element={<CardProfile />} />
            <Route path="user/*" element={<PrivateRoutes />} />
            <Route path="/interests/page/:id" element={<InterestingPage />} />
            {/* <Route path="*" element={<NotFound />} /> */}
            {/* <Route path="/my-quest" element={<MyQuest />} /> */}
            {/* <Route path="/create-quest" element={<CreateQuest />} /> */}
            {/* <Route path="/change-quest" element={<ChangeQuest />} /> */}
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

import Nav from "./Nav";
import logoImg from "../../images/png/logo (1).png";
import { Link, useNavigate } from "react-router-dom";
import Filter from "../Filter/Filter";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/middleware/AuthThunk";

const Header = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header__row">
          <div className="header__logo">
            <Link to="/">
              <img src={logoImg} alt="" />
            </Link>
            <p className="header__subtitle">Лучший досуг в Чебоксарах у нас</p>
          </div>

          <div className="header__auth-in">
            {!isAuth && (
              <div className="header__auth">
                <Link to="/login" className="header__signIn">
                  Вход
                </Link>
                <Link to="/register" className="header__signUp">
                  Регистрация
                </Link>
              </div>
            )}
            {isAuth && (
              <div className="header__logout">
                <button onClick={handleLogout}>Выйти</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Nav />
      {/* <Filter /> */}
    </header>
  );
};

export default Header;

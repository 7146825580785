import React, { useEffect, useState } from "react";
import StarRatings from "react-star-ratings";

const StarRating = ({ tempRating, rating, onRatingChange }) => {
  const [hoverRating, setHoverRating] = useState(null);
  const [needRating, setNeedRating] = useState(0);

  useEffect(() => {
    if (tempRating) {
      setNeedRating(tempRating);
    } else {
      setNeedRating(hoverRating === null ? rating : hoverRating);
    }
  }, [onRatingChange]);

  const handleStarHover = (newHoverRating) => {
    setHoverRating(newHoverRating);
  };

  const handleStarLeave = () => {
    setHoverRating(null);
  };

  const handleStarClick = (event) => {
    event.preventDefault(); // Полностью предотвращает действие по умолчанию
    event.stopPropagation(); // Также предотвращает распространение события
    onRatingChange();
  };

  console.log(tempRating);
  return (
    <div
      onClick={handleStarClick}
      className="star-rating-container"
      onMouseLeave={handleStarLeave} // Сброс ховера, когда мышь уходит
    >
      <StarRatings
        // rating={tempRating || hoverRating === null ? rating : hoverRating} // Используйте hoverRating при наведении
        rating={needRating}
        starRatedColor="gold"
        starEmptyColor="gray"
        numberOfStars={5}
        name="rating"
        starDimension="15px"
        starSpacing="1px"
        changeRating={onRatingChange}
        onStarHover={handleStarHover} // Обработчик наведения на звезды
      />
    </div>
  );
};

export default StarRating;

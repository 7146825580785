import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import ScrollToTop from "../ScrollTop/ScrollTop";

const Pagination = ({ pageChange }) => {
  const [pageCount, setPageCount] = useState(0);
  const { quests } = useSelector((state) => state.quest);
  const [currentPage, setCurrentPage] = useState(0);
  //   const dispatch = useAppDispatch();

  const handlePageClick = (event) => {
    // dispatch(setPageSw(event.selected + 1));
    // dispatch(getLimitProducts({ page: event.selected + 1 }));
    window.scrollTo(0, 0);
    pageChange(event.selected);
    setCurrentPage(event.selected);
  };

  const handleFirstPage = () => {
    window.scrollTo(0, 0);
    setCurrentPage(0);
    pageChange(0);
  };

  const handleLastPage = () => {
    window.scrollTo(0, 0);
    const lastPage = pageCount - 1;
    setCurrentPage(lastPage);
    pageChange(lastPage);
  };

  useEffect(() => {
    setPageCount(Math.ceil(quests.length / 70));
  }, [quests]);

  // console.log(pageCount);
  // console.log(currentPage);

  return (
    <>
      <div className="">
        <div className="pagination-controls">
          <button
            style={{
              background: `${currentPage !== 0 ? "red" : ""}`,
              color: `${currentPage !== 0 ? "#fff" : ""}`,
            }}
            onClick={handleFirstPage}
            disabled={currentPage === 0}
            className="page-item page-link"
          >
            Первая
          </button>
          <ReactPaginate
            breakLabel="..."
            nextLabel="Вперед"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="Назад"
            renderOnZeroPageCount={null}
            containerClassName="pagination-container"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            // previousClassName="page-item"
            previousClassName={`page-item ${
              currentPage !== 0 ? "prev-red" : "prev-white"
            }`}
            previousLinkClassName="page-link"
            // nextClassName="page-item"
            nextClassName={`page-item ${
              currentPage + 1 !== pageCount ? "next-red" : "next-white"
            }`}
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="page-item-active"
            forcePage={currentPage}
          />
          <button
            style={{
              background: `${currentPage + 1 !== pageCount ? "red" : ""}`,
              color: `${currentPage + 1 !== pageCount ? "#fff" : ""}`,
            }}
            onClick={handleLastPage}
            disabled={currentPage === pageCount - 1}
            className="page-item page-link"
          >
            Последняя
          </button>
        </div>
      </div>
    </>
  );
};

export default Pagination;

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createQuestTh,
  getAreaTh,
  getServicesTh,
  getTypeOfGirlTh,
  myQuestTh,
} from "../../store/middleware/QuestThunk";
import { setCreated } from "../../store/reducers/QuestSlice";

const CreateQuest = () => {
  const { services, myQuest, areas, created, typeOfGirl, myQuestState } =
    useSelector((state) => state.quest);
  const [sex, setSex] = useState("F");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAreaTh());
    dispatch(getTypeOfGirlTh());
    dispatch(getServicesTh());

    return () => {
      setCreated();
    };
  }, []);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      // first_name: "",
      // age: 0,
      // bust_size: 0,
      // cock: 0,
      // weight: 0,
      // height: 0,
      // phone_number: "",
      sex: "F",
      // photo: [],
      // services: [],
      // price: 0,
      // area: "",
      // about_me: "",
      // type_of_meet: "",
      // type_of_girl: "",
    },
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    // console.log(data);
    if (data.sex === "F") {
      data.cock = 0;
    }
    if (data.sex === "M") {
      data.bust_size = 0;
      // data.type_of_girl = "";
    }
    dispatch(createQuestTh({ ...data }));
  };

  const typeOfMeetValues = watch("services");
  console.log(typeOfMeetValues);

  if (myQuestState) {
    return (
      <div className="create-quest">
        <div className="container">
          <h2>У вас уже есть анкета</h2>
        </div>
      </div>
    );
  }

  if (created) {
    return (
      <div className="container">
        <div className="created">
          <h1>Анкета успешно создана</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="create-quest">
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)} className="create-quest__form">
          {/* title */}
          <h2 className="create-quest__title">Добавить анкету</h2>

          <div className="create-quest__sex">
            {/* <label htmlFor="sex">Пол <span>*</span></label> */}
            <select
              {...register("sex", {
                required: "Пол обязателен",
                onChange: (e) => setSex(e.target.value),
              })}
            >
              <option value="F">Выберите категорию</option>
              <option value="F">Индивидуалки</option>
              <option value="M">Мужчина</option>
            </select>
            {errors.sex && (
              <p className="my-quest__errorM">{errors.sex?.message}</p>
            )}
          </div>

          {/* title */}
          <h2 className="create-quest__title create-quest__title_2">
            Заполнить поля
          </h2>

          {/* form */}
          <div className="create-quest__name">
            {/* <label htmlFor="first_name">
              Заголовок <span>*</span>
            </label> */}
            <input
              {...register("first_name", { required: "Имя обязательно" })}
              type="text"
            />
            {errors.first_name && (
              <p className="my-quest__errorM">{errors.first_name.message}</p>
            )}
          </div>

          <div className="create-quest__description">
            {/* <label htmlFor="about_me">О себе</label> */}
            <textarea
              {...register("about_me", { required: "Обязательное поле" })}
            />
            {errors.about_me && (
              <p className="my-quest__errorM">{errors.about_me.message}</p>
            )}
          </div>

          <div className="create-quest__phone">
            {/* <label htmlFor="phone_number">
              Номер телефона <span>*</span>
            </label> */}
            <input
              maxLength={15}
              {...register("phone_number", {
                required: "Номер телефона обязателен",
                pattern: {
                  value: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
                  message:
                    "Ориентировано на российские мобильные + городские с кодом из 3 цифр",
                },
              })}
              type="text"
            />
            {errors.phone_number && (
              <p className="my-quest__errorM">{errors.phone_number?.message}</p>
            )}
          </div>

          {sex === "F" && (
            <div className="create-quest__bust_size">
              {/* <label htmlFor="bust_size">Грудь</label> */}
              <select {...register("bust_size")}>
                <option value="">Выберите размер</option>
                {[...Array(10)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
          )}

          {sex === "M" && (
            <div className="create-quest__cock">
              {/* <label htmlFor="cock">Член</label> */}
              <input {...register("cock")} type="text" />
            </div>
          )}

          <div className="create-quest__height">
            {/* <label htmlFor="height">
              Рост <span>*</span>
            </label> */}
            <input
              {...register("height", {
                required: "Рост обязателен",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Рост должен быть числом",
                },
              })}
              type="text"
            />
            {errors.height && (
              <p className="my-quest__errorM">{errors.height?.message}</p>
            )}
          </div>

          <div className="create-quest__weight">
            {/* <label htmlFor="weight">
              Вес <span>*</span>
            </label> */}
            <input
              {...register("weight", {
                required: "Вес обязателен",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Вес должен быть числом",
                },
              })}
              type="text"
            />
            {errors.weight && (
              <p className="my-quest__errorM">{errors.weight?.message}</p>
            )}
          </div>

          <div className="create-quest__age">
            {/* <label htmlFor="age">
              Возраст <span>*</span>
            </label> */}
            <input
              {...register("age", {
                required: "Возраст обязателен",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Возраст должен быть числом",
                },
              })}
              type="text"
            />
            {errors.age && (
              <p className="my-quest__errorM">{errors.age?.message}</p>
            )}
          </div>

          {/* <div className="create-quest__description">
            <label htmlFor="about_me">О себе</label>
            <textarea {...register("about_me")} />
          </div> */}

          <div className="create-quest__type_of_meet">
            {/* <label htmlFor="type_of_meet">
              Тип встречи <span>*</span>
            </label> */}
            <div className="">
              <input
                {...register("type_of_meet", { required: "Обязательное поле" })}
                type="checkbox"
                value="Апартаменты"
              />
              <span>Апартаменты</span>
            </div>
            <div className="">
              <input
                {...register("type_of_meet", { required: "Обязательное поле" })}
                type="checkbox"
                value="Выезд"
              />
              <span>Выезд</span>
            </div>

            <div className="">
              <input
                {...register("type_of_meet", { required: "Обязательное поле" })}
                type="checkbox"
                value="В авто"
              />
              <span>В авто</span>
            </div>

            {/* <select
              multiple
              {...register("type_of_meet", {
                required: "Тип встречи обязателен",
              })}
            >
              Апартементы
            </select> */}
            {errors.type_of_meet && (
              <p className="my-quest__errorM">{errors.type_of_meet?.message}</p>
            )}
          </div>

          <div className="create-quest__price">
            {/* <label htmlFor="price">
              Цена за час <span>*</span>
            </label> */}
            <input
              {...register("price", {
                required: "Цена за час обязательна",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Цена должна быть числом",
                },
              })}
              type="text"
            />
            {errors.price && (
              <p className="my-quest__errorM">{errors.price?.message}</p>
            )}
          </div>

          <div className="create-quest__area">
            {/* <label htmlFor="area">
              Район <span>*</span>
            </label> */}
            {areas.map((item, index) => (
              <div key={index} className="">
                <input
                  {...register("area", { required: "Район обязателен" })}
                  value={item.area}
                  type="checkbox"
                />
                <span>{item.area}</span>
              </div>
            ))}
            {/* <select {...register("area", { required: "Район обязателен" })}>
              <option value="">Выберите район</option>
              
            </select> */}
            {errors.area && (
              <p className="my-quest__errorM">{errors.area?.message}</p>
            )}
          </div>

          {/* {sex === "F" && (
            <div className="create-quest__type_of_girl">
              <label htmlFor="type_of_girl">Выберите уникальность</label>
              <select
                {...register("type_of_girl", {
                  required: "Униакальность обязательна",
                })}
              >
                <option value="">Выберите уникальность</option>
                {typeOfGirl.map((item, index) => (
                  <option key={index} value={item.type}>
                    {item.type}
                  </option>
                ))}
              </select>
              {errors.type_of_girl && (
                <p className="my-quest__errorM">
                  {errors.type_of_girl?.message}
                </p>
              )}
            </div>
          )} */}

          <div className="create-quest__services">
            {/* <label htmlFor="services">
              Услуги <span>*</span>
            </label> */}
            {services.map((item, index) => (
              <div key={index} className="">
                <input
                  {...register("services", { required: "Услуги обязательны" })}
                  value={item.service}
                  type="checkbox"
                />
                <span>{item.service}</span>
              </div>
            ))}
            {/* <select
              {...register("services", { required: "Услуги обязательны" })}
              multiple
            >
              {services.map((item, index) => (
                <option key={index} value={item.service}>
                  {item.service}
                </option>
              ))}

              
            </select> */}
            {errors.services && (
              <p className="my-quest__errorM">{errors.services?.message}</p>
            )}
          </div>

          <div className="create-quest__photo">
            {/* <label htmlFor="photo">
              Фото <span>*</span>
            </label> */}
            <input
              {...register("photo", { required: "Фото обязательно" })}
              type="file"
              multiple
            />
            {errors.photo && (
              <p className="my-quest__errorM">{errors.photo?.message}</p>
            )}
          </div>

          <div className="create-quest__btn">
            <button type="submit">Создать</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateQuest;

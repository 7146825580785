import { useEffect, useState } from "react";
import Card from "../Card/Card";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuestTh } from "../../store/middleware/QuestThunk";
import Pagination from "../Pagination/Pagination";
import ScrollToTop from "../ScrollTop/ScrollTop";

const Feed = () => {
  const dispatch = useDispatch();
  const { quests, filtered, isLoadingQuest } = useSelector(
    (state) => state.quest
  );
  const [data, setData] = useState([]);
  const [sorted, setSorted] = useState([]);

  useEffect(() => {
    dispatch(getAllQuestTh());
  }, []);

  useEffect(() => {
    const filteredd = quests.filter((item) => item.sex === "F").slice(0, 70);
    setData(filteredd);
  }, [quests]);

  useEffect(() => {
    setData(filtered.filter((item) => item.sex === "F".slice(0, 70)));
  }, [filtered]);

  const handleChangePage = (page) => {
    console.log(filtered);
    const startPage = page * 70;
    const endPage = startPage + 70;
    if (filtered.length !== 0) {
      setData(
        filtered.filter((item) => item.sex === "F").slice(startPage, endPage)
      );
    } else {
      setData(
        quests.filter((item) => item.sex === "F").slice(startPage, endPage)
      );
    }
  };

  if (isLoadingQuest) {
    return <h1 style={{ fontSize: "24px" }}>Загрузка</h1>;
  }

  return (
    <div className="feed">
      <h2 className="feed__title">Снять путану онлайн</h2>
      <p className="feed__text">
        Позвольте Вас познакомить: это - проститутки Чебоксар, очаровательные
        девушки, которые готовы заняться с вами самым зажигательным сексом,
        недорого. Сами себя они называют "индивидуалки". Что это означает на
        практике, и какой от этого толк нам, их потенциальным клиентам? Начнем с
        того, что основное преимущество - более персональный подход, чем в
        случае с конторскими шлюхами. Вас не будет торопить сутенер, мамочка, и
        в очереди за вами не столпится куча, желающих интима, после вас.
      </p>
      <p className="feed__text_2">
        Естественно, такой досуг предпочтительнее. 99% объявлений фей на нашем
        сайте - именно от проверенных индивидуалок, поэтому выбирайте смело.
        Удачного интима!
      </p>

      <div className="feed__cards">
        {/* {womanCardData.map((item, ind) => (
          <Card
            key={ind}
            name={item.name}
            age={item.age}
            phone={item.phone}
            boobs={item.boobs}
            tall={item.tall}
            weight={item.weight}
            description={item.description}
            price={item.price}
          />
        ))} */}
        {data.length === 0 && (
          <div className="men__no-anket">
            <h2>Нет анкет</h2>
          </div>
        )}

        {data.length !== 0 &&
          data.map((item) => (
            <Card
              id={item.id}
              key={item.id}
              name={item.first_name}
              age={item.age}
              phone={item.phone_number}
              penis={item.cock}
              boobs={item.bust_size}
              tall={item.height}
              weight={item.weight}
              description={item.about_me}
              price={item.price}
              img={item.photo}
              meet={item.type_of_meet}
              rating={item.rating}
              isVip={item.is_vip}
              isVerified={item.is_verified}
            />
          ))}
      </div>
      <Pagination pageChange={handleChangePage} />
    </div>
  );
};

export default Feed;

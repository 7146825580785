import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { getInterestsTh } from "../../store/reducers/InterestsSlice";
import { useParams } from "react-router-dom";

const InterestingPage = () => {
  const { interestsData } = useSelector((state) => state.interests);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getInterestsTh());
    const filtered = interestsData.filter((item) => item.id === +id)[0];
    setData(filtered);
  }, []);

  return (
    <div className="interesting-page">
      <div className="container">
        <div className="interesting-page__box">
          <h1 className="interesting-page__title">{data?.title}</h1>
          <p className="interesting-page__text">{data?.text}</p>
        </div>
      </div>
    </div>
  );
};

export default InterestingPage;

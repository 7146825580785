import $api from "../http/index";
import axios from "axios";
import { API_URL } from "../http/index";

export default class QuestService {
  static async createQuest(formData) {
    return $api.post("/anket/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  static async deleteMyQuest(id) {
    return $api.delete(`/anket/${id}/`);
  }
  static async getAllQuest() {
    return axios.get(`${API_URL}/anket/`);
  }
  static async getServices() {
    return axios.get(`${API_URL}/services/`);
  }
  static async getArea() {
    return axios.get(`${API_URL}/areas/`);
  }
  static async getTypeOfGirl() {
    return axios.get(`${API_URL}/type_of_girl/`);
  }
  static async myQuest() {
    return $api.get("/anket/my-anket/");
  }
  static async starsVote(id, stars) {
    return $api.post(`/anket/${id}/rating/`, { stars });
  }
  static async sendComment(id, owner, content) {
    return axios.post(`${API_URL}/anket/${id}/comment/`, { owner, content });
  }
}

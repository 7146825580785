import Comments from "../Comments/Comments";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuestTh, starsVoteTh } from "../../store/middleware/QuestThunk";
import ImageZoom from "../ImageZoom/ImageZoom";
import someImg from "../../images/content/1.jpg";
import StarRatings from "react-star-ratings";
import StarRating from "../StartRating/StartRating";
import noImg from "../../images/content/noImg.png";

const CardProfile = () => {
  const { quests } = useSelector((state) => state.quest);
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const { id } = useParams();
  const [tempRating, setTempRating] = useState(0);

  const handleStarClick = (newRating) => {
    if (newRating) {
      data.rating === 0
        ? setTempRating(data.rating + newRating)
        : setTempRating((data.rating + newRating) / 2);
    }

    dispatch(starsVoteTh({ id, stars: newRating }));
    // setTimeout(() => {
    //   dispatch(getAllQuestTh());
    // }, 300);
  };

  // useEffect(() => {
  //   dispatch(getAllQuestTh());
  // }, []);

  useEffect(() => {
    const filtered = quests.filter((item) => item.id === +id);
    const obj = filtered[0];
    setData(obj);
  }, [quests, id]);

  return (
    <div className="card-profile">
      <div className="container">
        <div className="card-profile__box">
          <div className="card-profile__up-row">
            <div className="card-profile__left">
              <h2 className="card-profile__name">{data?.first_name}</h2>
              <h1 className="card-profile__phone">
                <a style={{ color: "red" }} href={`tel:${data?.phone_number}`}>
                  {data?.phone_number}
                </a>
              </h1>
              <p className="card-profile__warning">
                Не давайте предоплату, вас обманут!
              </p>
            </div>

            <h3 className="card-profile__price">
              {data?.price > 9999
                ? data?.price?.toString().slice(0, 2) +
                  " " +
                  data?.price?.toString().slice(2) +
                  " "
                : data?.price?.toString()[0] +
                  " " +
                  data?.price?.toString().slice(1) +
                  " "}
              руб/час
            </h3>
          </div>

          <div className="card-profile__row">
            <div className="card-profile__imgs">
              {data?.photo.map((item, index) => (
                <div key={index} className="card-profile__img">
                  {/* <img src={`http://188.225.79.205/${item.image}`} alt="" /> */}
                  <ImageZoom
                    src={`https://sex-prostitutki-21.ru/${item.image}`}
                    alt={
                      <img
                        src={`https://sex-prostitutki-21.ru/${item.image}`}
                        alt=""
                      />
                    }
                  />
                </div>
              ))}
              {data?.photo.length === 0 && (
                <div className="card-profile__img">
                  <ImageZoom src={noImg} alt="" />
                </div>
              )}
            </div>

            <div className="card-profile__content">
              <div className="card-profile__about-me ">
                <h3>О себе:</h3>
                <p>{data?.about_me}</p>

                <h5 className="card-profile__rating">Рейтинг:</h5>
                <div className="star-rating-container">
                  {/* <StarRatings
                    rating={data?.rating}
                    starRatedColor="rgb(224,181,36)"
                    numberOfStars={5}
                    name="rating"
                    starDimension="15px" // Размер звезд
                    starSpacing="1px" // Пробел между звездами
                    // changeRating={onRatingChange}
                  /> */}
                  <StarRating
                    tempRating={tempRating}
                    rating={data?.rating}
                    onRatingChange={handleStarClick}
                  />
                </div>
              </div>

              <div className="card-profile__description">
                <h3>Описание</h3>
                <ul>
                  <li>
                    <strong>Район:</strong>{" "}
                    {data?.area?.map((item) => `${item},`)}
                  </li>
                  <li>
                    <strong>Возраст:</strong> {data?.age}
                  </li>
                  <li>
                    <strong>Рост:</strong> {data?.height}
                  </li>
                  <li>
                    <strong>Вес:</strong> {data?.weight}
                  </li>
                  {data?.bust_size !== 0 ? (
                    <li>
                      <strong>Грудь:</strong> {data?.bust_size}
                    </li>
                  ) : (
                    <li>
                      <strong>Член:</strong> {data?.cock}
                    </li>
                  )}
                </ul>
              </div>

              <div className="card-profile__services">
                <h3>Услуги:</h3>

                <ul className="">
                  {data?.services.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>

              <div className="card-profile__meet">
                <h3>Варианты встреч:</h3>
                <ul>
                  {data?.type_of_meet.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>

              {/* <div className="my-quest__type">
                <h3>Типаж</h3>
                <p>{data?.type_of_girl}</p>
              </div> */}

              {/* <div className="card-profile__map">
                <h3>Район:</h3>
                <p>{data?.area}</p>
              </div> */}
              {/* <div className="card-profile__map">
                <h3>На карте:</h3>
                <ul>
                  <li></li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div className="card-profile__comments-block">
          <Comments id={id} comments={data?.comments} />
        </div>
      </div>
    </div>
  );
};

export default CardProfile;

import axios from "axios";
import $api, { API_URL } from "../http/index";

export default class AuthService {
  static async registration(email, password, passwordConfirm) {
    return axios.post(`${API_URL}/user/register/`, {
      email,
      password,
      password_confirm: passwordConfirm,
    });
  }
  static async forgotPass(email) {
    return axios.post(`${API_URL}user/forgot_password/`, {
      email,
    });
  }
  static async newPass(code_confirm, new_password, password_confirm) {
    return axios.post(`${API_URL}user/forgot_password_confirm/`, {
      code_confirm,
      new_password,
      password_confirm,
    });
  }
  static async login(email, password) {
    return axios.post(`${API_URL}/user/login/`, { email, password });
  }
  static async checkAuth() {
    const refreshToken = localStorage.getItem("refreshToken");
    return $api.post("/user/refresh/", {
      refresh: refreshToken,
    });
  }
  static async logout() {
    $api.post("/user/logout/");
  }
}

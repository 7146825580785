import { useEffect, useState } from "react";
import Comments from "../../components/Comments/Comments";
import { useDispatch, useSelector } from "react-redux";
import {
  myQuestTh,
  deleteMyQuestTh,
  getAllQuestTh,
  starsVoteTh,
} from "../../store/middleware/QuestThunk";
import ImageZoom from "../../components/ImageZoom/ImageZoom";
import WatermarkedImage from "../../components/WatermarkedImage/WatermarkedImage";
import someImg from "../../images/content/1.jpg";
import StarRating from "../../components/StartRating/StartRating";
import noImg from "../../images/content/noImg.png";
import StarRatingWithout from "../../components/StartRating/StartRatingWithout";

const MyQuest = () => {
  const [willDelete, setWillDelete] = useState(false);
  const [error, setError] = useState(false);
  const { myQuest, myQuestError } = useSelector((state) => state.quest);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(myQuestTh());
  }, [error]);

  const handleDelete = () => {
    const id = myQuest?.id;
    dispatch(deleteMyQuestTh({ id }));
    setWillDelete(false);
    dispatch(myQuestTh());
    setError(true);
  };

  if (myQuestError) {
    return (
      <div className="my-quest__error">
        <div className="container">
          <h2>У вас еще нет анкеты</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="my-quest">
      {willDelete && (
        <div className="my-quest__will-delete">
          <div className="my-quest__will-btns">
            <button onClick={handleDelete}>Да</button>
            <button onClick={() => setWillDelete(false)}>Отмена</button>
          </div>
        </div>
      )}
      <div className="container">
        <div className="my-quest__panel">
          {/* <div className="my-quest__change">
            <Link to="/change-quest">Изменить</Link>
          </div> */}
          <div className="my-quest__delete">
            <button
              onClick={() => setWillDelete(true)}
              className="my-quest__delete"
            >
              Удалить
            </button>
          </div>
        </div>
        <div className="my-quest__box">
          <div className="my-quest__up-row">
            <div className="my-quest__left">
              <h2 className="my-quest__name">{myQuest.first_name}</h2>
              <h1 className="my-quest__phone">{myQuest.phone_number}</h1>
              <p className="my-quest__warning">
                Не давайте предоплату, вас обманут!
              </p>
            </div>

            <h3 className="my-quest__price">
              {myQuest?.price > 9999
                ? myQuest?.price?.toString().slice(0, 2) +
                  " " +
                  myQuest?.price?.toString().slice(2) +
                  " "
                : myQuest?.price?.toString()[0] +
                  " " +
                  myQuest?.price?.toString().slice(1) +
                  " "}
              руб/час
            </h3>
          </div>

          <div className="my-quest__row">
            <div className="my-quest__imgs">
              {myQuest?.photo?.map((item, index) => (
                <div key={index} className="my-quest__img">
                  {/* <img src={`http://188.225.79.205/${item.image}`} alt="" /> */}

                  <ImageZoom
                    src={`https://sex-prostitutki-21.ru/${item.image}`}
                    alt=""
                  />

                  {/* <WatermarkedImage
                    src={`http://188.225.79.205/${item.image}`}
                    y={100}
                    x={100}
                    src={someImg}
                    watermarkText="prost"
                    alt=""
                  /> */}
                </div>
              ))}

              {myQuest?.photo?.length === 0 && (
                <div className="my-quest__img">
                  <ImageZoom
                    src={`https://sex-prostitutki-21.ru/${noImg}`}
                    alt=""
                  />
                </div>
              )}

              {/* <div className="my-quest__img">
            <img src={testImg} alt="" />
          </div> */}
            </div>

            <div className="my-quest__content">
              <div className="my-quest__about-me ">
                <h3>О себе:</h3>
                <p>{myQuest.about_me}</p>

                <h5 className="card-profile__rating">Рейтинг:</h5>
                <div className="star-rating-container">
                  <StarRatingWithout rating={myQuest.rating} />
                </div>
              </div>

              <div className="my-quest__description">
                <h3>Описание</h3>
                <ul>
                  <li>
                    <strong>Район:</strong> {myQuest.area}
                  </li>
                  <li>
                    <strong>Возраст:</strong> {myQuest.age}
                  </li>
                  <li>
                    <strong>Рост:</strong> {myQuest.height}
                  </li>
                  <li>
                    <strong>Вес:</strong> {myQuest.weight}
                  </li>
                  <li>
                    {myQuest.bust_size !== 0 ? (
                      <li>
                        <strong>Грудь: </strong>
                        {myQuest.bust_size}
                      </li>
                    ) : (
                      <li>
                        <strong>Член:</strong> {myQuest.cock}
                      </li>
                    )}
                  </li>
                </ul>
              </div>

              <div className="my-quest__services">
                <h3>Услуги:</h3>
                <ul>
                  <div className="">
                    {myQuest.services &&
                      myQuest.services.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                  </div>
                </ul>
              </div>

              <div className="my-quest__meet">
                <h3>Варианты встреч:</h3>
                <ul>
                  {myQuest.type_of_meet &&
                    myQuest.type_of_meet.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                </ul>
              </div>

              {/* <div className="my-quest__type">
                <h3>Типаж</h3>
                <p>{myQuest.type_of_girl}</p>
              </div> */}

              {/* <div className="my-quest__map">
                <h3>На карте:</h3>
                <ul>
                  <li></li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div className="my-quest__comments-block">
          <Comments without comments={myQuest.comments} />
        </div>
      </div>
    </div>
  );
};

export default MyQuest;

import React, { useEffect, useState } from "react";
import watermark from "watermarkjs";

const WatermarkedImage = ({ src, watermarkText }) => {
  const [imageSrc, setImageSrc] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(src, { mode: "cors" });
        const blob = await response.blob();
        const localUrl = URL.createObjectURL(blob);

        watermark([localUrl])
          .image(
            watermark.text.lowerRight(watermarkText, "48px serif", "#fff", 0.5)
          )
          .then((img) => {
            setImageSrc(img.src);
            URL.revokeObjectURL(localUrl); // Освобождение памяти
          })
          .catch((error) => {
            console.error("Error adding watermark:", error);
            setError(error);
          });
      } catch (error) {
        console.error("Error fetching image:", error);
        setError(error);
      }
    };

    fetchImage();
  }, [src, watermarkText]);

  if (error) {
    return <p>Error loading image: {error.message}</p>;
  }

  return (
    <div>
      {imageSrc ? <img src={imageSrc} alt="Watermarked" /> : <p>Loading...</p>}
    </div>
  );
};

export default WatermarkedImage;

const Map = () => {
  return (
    <div className="map">
      <div className="container">
        <div className="map__box">
          <h1 className="map__title">Карта апартаментов</h1>
          <p className="map__text">
            На этой карте вы можете найти адреса девушек, указавших свою
            геолокацию при оформлении анкеты. Очень удобная фишка для мужчин, не
            желающих терять время на лишние поиски проституток - можно сразу
            поехать на нужный район.
          </p>

          <div className="map__api"></div>
        </div>
      </div>
    </div>
  );
};

export default Map;

import { Element } from "react-scroll";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedTh } from "../../store/reducers/ChatSlice";

const Chat = ({ sendMessageFun }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const { chatMessages, connectedUsers } = useSelector((state) => state.chat);
  const { connected, messages } = useSelector((state) => state.websocket);
  // const { myQuest, } = useSelector((state) => state.quest);

  const handleSendMessage = () => {
    sendMessageFun({ message: value });
    setValue("");
  };

  useEffect(() => {
    if (connected) {
      dispatch(getConnectedTh());
    }
  }, [connected]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getConnectedTh());
    }, 1000 * 60 * 1);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleToMessage = (user) => {
    setValue(`${user} `);
  };

  return (
    <div className="chat">
      <Element name="chat">
        <h2 className="chat__title">Чатик</h2>

        <div className="chat__row">
          <div className="chat__users">
            <ul>
              {connectedUsers.user_ids?.map((item, ind) => (
                <li onClick={() => handleToMessage(item)} key={ind}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="chat__messages message">
            <ul className="message__list">
              {/* <li>
                <span>Гость_7833:</span>
                Если молода симпатичная на выезд? Отпишись пожалуйста)
              </li> */}
              {chatMessages.map((item, ind) => (
                <li key={ind} className="message__list">
                  <span onClick={() => handleToMessage(item.username)}>
                    {item.username}:
                  </span>
                  {item.message}
                </li>
              ))}
              {messages.map((item, ind) => (
                <li key={ind} className="message__list">
                  <span>{item.username}:</span>
                  {item.message}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* <p className="chat__current-user">Имя: </p> */}

        <form className="chat__form" action="">
          <label htmlFor="">Сообщение:</label>
          <textarea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="chat__message"
            name=""
            id=""
          ></textarea>
          <button
            onClick={handleSendMessage}
            type="button"
            className="chat__btn"
          >
            Отправить
          </button>
          {/* <button onClick={connect} type="button" className="chat__btn">
            Соед
          </button> */}
        </form>
      </Element>
    </div>
  );
};

export default Chat;

const Contacts = () => {
  return (
    <div className="contacts">
      <div className="container">
        <div className="contacts__box">
          <h1 className="contacts__title">Контакты администрации</h1>
          <p className="contacts__text">
            Во вопросам работы сайта, жалобам, а также по размещению анкет
            пишите нам: <span>sitebott.red21@mail.ru</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contacts;

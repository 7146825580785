import React, { useEffect, useState } from "react";
import Card from "../../components/Card/Card";
import manImg from "../../images/content/2.webp";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuestTh } from "../../store/middleware/QuestThunk";

const Men = () => {
  const { quests } = useSelector((state) => state.quest);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getAllQuestTh());
  }, []);

  useEffect(() => {
    const filtered = quests.filter((item) => item.sex === "M");
    setData(filtered);
  }, [quests]);

  return (
    <div className="men">
      <div className="container">
        <h2 className="men__title">Парни.</h2>
        <p className="men__text">
          Новый раздел сайта, посвящённый мужчинам, желающим познакомиться для
          приятного времяпровождения, с женщинами на платной и бесплатной
          основе. Так называемые, альфонсы и жигало для одиноких богатых дам.
          "Муж на час" - услуга, очень востребованная в Чебоксарах, но не все
          девушки знают, где их найти. Отвечаем: "Тут!".
        </p>
        {}
        <div className="men__row">
          {data.length === 0 && (
            <div className="men__no-anket">
              <h2>Нет анкет</h2>
            </div>
          )}
          {data.length !== 0 &&
            data.map((item) => (
              <Card
                id={item.id}
                key={item.id}
                name={item.first_name}
                age={item.age}
                phone={item.phone_number}
                boobs={item.bust_size}
                penis={item.cock}
                tall={item.height}
                weight={item.weight}
                description={item.about_me}
                price={item.price}
                img={item.photo}
                meet={item.type_of_meet}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Men;

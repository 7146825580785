import Chat from "../Chat/Chat";
import Feed from "../Feed/Feed";
import Filter from "../Filter/Filter";
import { useEffect } from "react";
import { Element, scroller } from "react-scroll";
import { useLocation } from "react-router-dom";

const FeedLayout = ({ sendMessageFun }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      scroller.scrollTo(id, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [location]);

  return (
    <>
      <Filter />
      <div className="feed-layout">
        <div className="container">
          <div className="feed-layout__row">
            <Chat sendMessageFun={sendMessageFun} />
            {/* <Element name="chat"> */}
            <Feed />
            {/* </Element> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedLayout;

import { FC } from "react";
import { Link } from "react-router-dom";
import Burger from "../Burger/Burger";
import { closeBurger } from "../../store/reducers/BurgerSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link as LinkScroll } from "react-scroll";

const Nav = () => {
  const { burger } = useSelector((state) => state.burger);
  const dispatch = useDispatch();

  return (
    <nav className="nav">
      <div className="container">
        <div className={`nav__nav ${burger ? "active" : ""}`}>
          <ul className={`nav__list ${burger ? "active" : ""}`}>
            <li onClick={() => dispatch(closeBurger())} className="nav__link">
              <Link to="/">Индивидуалки</Link>
            </li>
            <li onClick={() => dispatch(closeBurger())} className="nav__link">
              <Link to="/men">Мужчины</Link>
            </li>
            {/* <li onClick={() => dispatch(closeBurger())} className="nav__link">
              <Link to="/map">Карта</Link>
            </li> */}
            {/* <li onClick={() => dispatch(closeBurger())} className="nav__link">
              <Link to="/interesting">Интересное</Link>
            </li> */}
            <li onClick={() => dispatch(closeBurger())} className="nav__link">
              <Link to="/contacts">Контакты</Link>
            </li>
            <li onClick={() => dispatch(closeBurger())} className="nav__link">
              <Link to="/rules">Правила</Link>
            </li>
            <li onClick={() => dispatch(closeBurger())} className="nav__link">
              <Link to="/user/create-quest">+Добавить анкету</Link>
            </li>
            <li onClick={() => dispatch(closeBurger())} className="nav__link">
              <Link to="/user/my-quest">Мои анкеты</Link>
            </li>
          </ul>
        </div>
        <div className="nav__row">
          <Burger />
          <ul className="nav__additional">
            <li className="nav__link">
              {/* <LinkScroll to="chat" smooth={true} duration={500}> */}
              <Link to="/#chat">Чат</Link>
              {/* </LinkScroll> */}
            </li>
            {/* <li className="nav__link">
              <Link to="/map">Карта</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;

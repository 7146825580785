import $api from "../http/index";
import axios from "axios";
import { API_URL } from "../http/index";

export default class ChatService {
  static async getChat() {
    return $api.get("/get_messages/");
  }
  static async getConnected() {
    return axios.get(`${API_URL}/connected-users/`);
  }
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import InterestsService from "../../services/InterestsService";

export const getInterestsTh = createAsyncThunk(
  "quest/getInterests",
  async (_, { rejectWithValue }) => {
    try {
      const response = await InterestsService.getInterests();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

const initialState = {
  interestsData: [],
  error: false,
  isLoading: false,
};

const InterestsSlice = createSlice({
  name: "interests",
  initialState,
  reducers: {
    setIsRegistrated: (state) => {
      state.isRegistrated = false;
    },
    setForgotConfirm: (state) => {
      state.forgotConfirm = false;
    },
  },
  extraReducers: (builder) => {
    //interests
    builder
      .addCase(getInterestsTh.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInterestsTh.fulfilled, (state, action) => {
        state.isLoading = false;
        state.interestsData = action.payload;
      })
      .addCase(getInterestsTh.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {} = InterestsSlice.actions;
export default InterestsSlice.reducer;

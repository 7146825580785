import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import StarRating from "../StartRating/StartRating";
import { getAllQuestTh, starsVoteTh } from "../../store/middleware/QuestThunk";
import noImg from "../../images/content/noImg.png";
import { useState } from "react";

const Card = ({
  name,
  phone,
  age,
  boobs,
  tall,
  weight,
  price,
  penis,
  description,
  img,
  id,
  rating,
  meet,
  isVerified,
  isVip,
}) => {
  const dispatch = useDispatch();
  const [tempRating, setTempRating] = useState(0);

  const handleStarClick = (newRating) => {
    if (newRating) {
      rating === 0
        ? setTempRating(rating + newRating)
        : setTempRating((rating + newRating) / 2);
    }

    dispatch(starsVoteTh({ id, stars: newRating }));
    // setTimeout(() => {
    //   dispatch(getAllQuestTh());
    // }, 300);
  };

  return (
    <div className="card">
      <Link to={`/card/card-profile/${id}`}>
        <div className="card__row">
          <div className="card__img">
            {img.length !== 0 ? (
              <img
                src={`https://sex-prostitutki-21.ru/${img[0]?.image}`}
                alt=""
              />
            ) : (
              <img src={noImg} alt="" />
            )}

            <div className="star-rating-container-card">
              <StarRating
                tempRating={tempRating}
                rating={rating}
                onRatingChange={handleStarClick}
              />
            </div>
          </div>

          <div className="card__content">
            <h5 className="card__name">{name}</h5>
            <p onClick={(e) => e.stopPropagation()} className="card__phone">
              <a href={`tel:${phone}`}>{phone}</a>
            </p>

            <ul className="card__list">
              <li className="card__age">
                <strong>Возраст:</strong> {age}
              </li>
              {boobs !== 0 ? (
                <li className="card__boobs">
                  <strong>Грудь: </strong>
                  {boobs}
                </li>
              ) : (
                <li>
                  <strong>Член:</strong> {penis}
                </li>
              )}

              <li className="card__tall">
                <strong>Рост:</strong> {tall}
              </li>
              <li className="card__weight">
                <strong>Вес:</strong> {weight}
              </li>
            </ul>

            <h4 className="card__price">
              {+price > 9999
                ? price.toString().slice(0, 2) +
                  " " +
                  price.toString().slice(2) +
                  " "
                : price.toString()[0] + " " + price.toString().slice(1) + " "}
              руб/час
            </h4>

            <p className="card__description">{description}</p>
          </div>
        </div>
      </Link>
      <div className="card__privileges">
        {isVip && <p className="card__vip">VIP</p>}
        {meet.includes("Выезд") && <p className="card__exit">ЕСТЬ ВЫЕЗД</p>}
        {isVerified && <p className="card__verified">ФОТО 100%</p>}
      </div>
    </div>
  );
};

export default Card;

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { login } from "../../store/middleware/AuthThunk";
import Errors from "../../components/Errors/Errors";
import { setError } from "../../store/reducers/AuthSlice";
import { useForm } from "react-hook-form";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);

  const { isAuth, isLoading, error, data } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    dispatch(
      login({
        email: data.email,
        password: data.password,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(setError(false));
    };
  }, []);

  if (isAuth) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return (
      <div className="container">
        <h1>Загрузка</h1>
      </div>
    );
  }

  return (
    <div className="login auth">
      <div className="container">
        <p className="login__text">
          Если у вас уже есть кабинет, введите вашу почту и пароль. Если вы
          новичок, то зарегистрируйтесь здесь
        </p>

        {/* errors block */}
        {error && (
          <Errors>
            Имя пользователя и пароль не совпадают или у вас ещё нет учётной
            записи на сайте
          </Errors>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="login__form"
          action=""
        >
          <div className="login__email">
            <label htmlFor="">Логин(E-mail) *</label>
            <input {...register("email")} type="email" />
          </div>

          <div className="login__password">
            <label htmlFor="">Пароль *</label>
            <input {...register("password")} type="password" />
          </div>

          <div className="login__remember">
            {/* <label htmlFor="">Запомнить меня</label>
            <input
              value={remember}
              onChange={(e) => setRemember(e.target.value)}
              type="checkbox"
            /> */}
          </div>

          <div className="login__btns">
            <button className="login__log-btn">Войти</button>
          </div>
        </form>

        <div className="login__nav">
          <Link to="/register">Регистрация</Link>
          {/* <Link to="/forgot-pass">Забыли пароль?</Link> */}
        </div>
      </div>
    </div>
  );
};

export default Login;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  connected: false,
  messages: [],
};

const WebSocketSlice = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    setConnected(state, action) {
      state.connected = action.payload;
    },
    setMessages(state, action) {
      state.messages = [...state.messages, action.payload];
    },
  },
});

export const { setConnected, setMessages } = WebSocketSlice.actions;
export default WebSocketSlice.reducer;

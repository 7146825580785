import React, { useState } from "react";
import StarRatings from "react-star-ratings";

const StarRatingWithout = ({ rating, onRatingChange }) => {
  const [hoverRating, setHoverRating] = useState(null);

  const handleStarHover = (newHoverRating) => {
    setHoverRating(newHoverRating);
  };

  const handleStarLeave = () => {
    setHoverRating(null);
  };

  const handleStarClick = (event) => {
    event.preventDefault(); // Полностью предотвращает действие по умолчанию
    event.stopPropagation(); // Также предотвращает распространение события
    onRatingChange();
  };

  return (
    <div
      onClick={(e) => e.preventDefault()}
      className="star-rating-container"
      onMouseLeave={handleStarLeave} // Сброс ховера, когда мышь уходит
    >
      <StarRatings
        rating={rating} // Используйте hoverRating при наведении
        starRatedColor="gold"
        starEmptyColor="gray"
        numberOfStars={5}
        name="rating"
        starDimension="15px"
        starSpacing="1px"
      />
    </div>
  );
};

export default StarRatingWithout;

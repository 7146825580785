import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { forgotPassTh, newPassTh } from "../../store/middleware/AuthThunk";
import Errors from "../../components/Errors/Errors";
import {
  setForgotConfirm,
  setForgotEmail,
} from "../../store/reducers/AuthSlice";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [code, setCode] = useState("");

  const {
    isAuth,
    isLoading,
    forgotEmail,
    forgotConfirm,
    forgotEmailMess,
    forgotConfirmMess,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setForgotConfirm());
      dispatch(setForgotEmail());
    };
  }, []);

  const handleEmail = () => {
    dispatch(forgotPassTh({ email }));
  };
  const handleNewPass = () => {
    dispatch(
      newPassTh({
        code_confirm: code,
        new_password: password,
        password_confirm: passwordConfirm,
      })
    );
  };

  if (isAuth) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return (
      <div style={{ marginTop: "20px" }} className="container">
        <h1>Загрузка</h1>
      </div>
    );
  }

  if (forgotConfirm) {
    return (
      <div className="forgot__confirm">
        <div className="container">
          <h2>Пароль успешно сменен</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="forgot auth">
      <div className="container">
        <p className="forgot__text">
          Пожалуйста, введите адрес электронной почты, указанный в параметрах
          вашей учётной записи. На него будет отправлен специальный проверочный
          код. После его получения вы сможете ввести новый пароль для вашей
          учётной записи.
        </p>

        {/* errors block */}
        {forgotEmailMess && <Errors>Нет такого email адреса</Errors>}

        {!forgotEmail && (
          <form
            onSubmit={(e) => e.preventDefault()}
            className="forgot__form"
            action=""
          >
            <div className="forgot__email">
              <label htmlFor="">Логин(E-mail) *</label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
              />
            </div>

            <div className="forgot__btns">
              <button onClick={handleEmail} className="login__log-btn">
                Отправить
              </button>
            </div>
          </form>
        )}
        {forgotEmail && (
          <>
            {forgotConfirmMess && (
              <Errors>Неверный код подтверждения или пароль</Errors>
            )}
            <form onSubmit={(e) => e.preventDefault()} action="">
              <div className="forgot__code">
                <label htmlFor="">Код *</label>
                <input
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  type="email"
                />
              </div>
              <div className="forgot__new-pass">
                <label htmlFor="">Новый пароль *</label>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="email"
                />
              </div>
              <div className="forgot__pass-conf">
                <label htmlFor="">Подтвердить пароль *</label>
                <input
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  type="email"
                />
              </div>

              <div className="forgot__btns">
                <button onClick={handleNewPass} className="login__log-btn">
                  Отправить
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;

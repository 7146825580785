import { useEffect, useState } from "react";
import Comment from "./Comment";
import {
  getAllQuestTh,
  sendCommentTh,
} from "../../store/middleware/QuestThunk";
import { useDispatch } from "react-redux";

const Comments = ({ without, id, comments }) => {
  const [reviews, setReviews] = useState(true);
  const [owner, setOwner] = useState("");
  const [content, setContent] = useState("");
  const [send, setSend] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllQuestTh());
  }, [send]);

  const handleSend = () => {
    dispatch(sendCommentTh({ id, owner, content }));
    setSend((prev) => !prev);
    setOwner("");
    setContent("");
  };

  return (
    <div className="comments">
      {reviews && (
        <div className="comments__reviews">
          <h2>Отзывы</h2>

          <div className="comments__reviews-row">
            {comments?.map((item, index) => (
              <Comment key={index} owner={item.owner} content={item.content} />
            ))}
          </div>
        </div>
      )}

      {!without && (
        <>
          <h2 className="comments__title">Оставить отзыв</h2>

          <form action="" className="comments__form">
            <div className="comments__name">
              <input
                maxLength={15}
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
                type="text"
              />
              <label htmlFor="">Ваше имя</label>
            </div>

            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              name=""
              id=""
              className="comments__message"
            ></textarea>

            <div className="comments__btns">
              <button
                onClick={handleSend}
                type="button"
                className="comments__send"
              >
                Отправить
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default Comments;

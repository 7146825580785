const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__row">
          <p>© 2024 Prostitutki-21</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

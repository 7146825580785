import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Chat from "../../components/Chat/Chat";
import Feed from "../../components/Feed/Feed";
import FeedLayout from "../../components/layouts/FeedLayout";


const HomePage = ({ sendMessageFun }) => {
  return (
    <div className="home-page">
      <FeedLayout sendMessageFun={sendMessageFun} />
    </div>
  );
};

export default HomePage;

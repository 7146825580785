import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/AuthService";
import {
  registration,
  login,
  logout,
  checkAuth,
  forgotPassTh,
  newPassTh,
} from "../middleware/AuthThunk";

const initialState = {
  data: null,
  error: false,
  isLoading: false,
  isAuth: false,
  isAuthProcessing: true,
  isRegistrated: false,
  forgotEmail: false,
  forgotConfirm: false,
  forgotEmailMess: false,
  forgotConfirmMess: false,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsRegistrated: (state) => {
      state.isRegistrated = false;
    },
    setForgotConfirm: (state) => {
      state.forgotConfirm = false;
    },
    setForgotEmail: (state) => {
      state.forgotEmail = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    //register
    builder
      .addCase(registration.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(registration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isRegistrated = true;
        state.data = action.payload;
      })
      .addCase(registration.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })

      //login
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isAuth = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      })

      //logout
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isAuth = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.isAuth = false;
      })

      //checkAuth
      .addCase(checkAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthProcessing = false;
        state.isAuth = true;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        localStorage.clear();
        state.isLoading = false;
        state.error = action.payload;
        state.isAuth = false;
        state.isAuthProcessing = false;
      })
      //forgotPass
      .addCase(forgotPassTh.pending, (state) => {
        state.isLoading = true;
        state.forgotEmailMess = false;
      })
      .addCase(forgotPassTh.fulfilled, (state, action) => {
        state.isLoading = false;
        state.forgotEmail = true;
      })
      .addCase(forgotPassTh.rejected, (state, action) => {
        state.isLoading = false;
        state.forgotEmailMess = true;
      })
      //newPass
      .addCase(newPassTh.pending, (state) => {
        state.isLoading = true;
        state.forgotConfirmMess = false;
      })
      .addCase(newPassTh.fulfilled, (state, action) => {
        state.isLoading = false;
        state.forgotConfirmMess = false;
        state.forgotConfirm = true;
      })
      .addCase(newPassTh.rejected, (state, action) => {
        state.isLoading = false;
        state.forgotConfirmMess = true;
      });
  },
});

export const { setIsRegistrated, setForgotConfirm, setForgotEmail, setError } =
  AuthSlice.actions;
export default AuthSlice.reducer;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getInterestsTh } from "../../store/reducers/InterestsSlice";

const Interesting = () => {
  const { interestsData } = useSelector((state) => state.interests);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInterestsTh());
  }, []);

  return (
    <div className="interesting">
      <div className="container">
        <ul className="interesting__list">
          {interestsData?.map((item) => (
            <li className="interesting__link">
              <Link to={`/interests/page/${item.id}`}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Interesting;
